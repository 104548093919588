import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

import moment from "moment"
import "moment/locale/hu.js"

import PostPreview from "../components/post-preview"
import "./main.css"

moment.locale('hu');

export default function IndexPage({ data }) {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      <SEO title="Kezdőoldal" />
      <div className="blog-posts">
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {

            return (
              <PostPreview key={post.id} post={post}/>
            )
          })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date
            path
            author
          }
        }
      }
    }
  }
`
