import { Link } from "gatsby"
import React from "react"
import * as PropTypes from "prop-types"
import moment from "moment"
import "moment/locale/hu.js"

import "./post-preview.css"

moment.locale("hu")

export default function PostPreview(props) {
  const post = props.post
  const formattedDate = moment(post.frontmatter.date).locale("hu").format("LL")

  return (
    <div className="blog-post-preview">
      <h2>
        <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
      </h2>
      <p>{post.excerpt}</p>
      <h5>
        {post.frontmatter.author} - {formattedDate}
      </h5>
    </div>
  )
}

PostPreview.propTypes = {
  post: PropTypes.any,
  formattedDate: PropTypes.string,
}
